import React from 'react';
import styles from './InstructionPage.module.scss';

class InstructionPage extends React.Component {

  constructor(props){
    super(props);
    this.state={
      visible: true
    }
    this.textBlocks=["Mit den Pfeiltasten durch den Himmel bewegen", "Durch Klicken und Ziehen die Blickrichtung ändern", "Die Objekte anklicken für  mehr Informationen"]
    this.iconSources=["instructionIcon12.png", "instructionIcon3.png", "instructionIcon2.png"]
  }

  closePage() {
    this.setState({visible: false});
  }

  render(){
    var instructionSets = [];
    for(let i = 0; i < 3; i++) {
      instructionSets.push(
        <div key={"set"+i} className={styles.setContainer}>
          <div className={styles.iconImage} style={{backgroundImage: "url("+this.iconSources[i]+")"}}></div>
          <div className={styles.textContainer}>
            <div className={styles.dotImage}></div>
            <div className={styles.text}>{this.textBlocks[i]}</div>
          </div>
        </div>
      )
    }

    return (
      <div className={(this.state.visible ? styles.mainContainer : styles.mainContainerHide)}>
        <div className={styles.cardContainer}>
          {instructionSets}
          <div className={styles.closeButton} onClick={() => this.closePage()}></div>
        </div>
      </div>
    )
  }
}

export default InstructionPage
