import React from 'react';
import styles from './InfoSlider.module.scss';

class InfoSlider extends React.Component {

  constructor(props){
    super(props);
    this.state={showInfo: true};
    
  }

  ToggleInfo = () => {
    //this.setState({showInfo: !this.state.showInfo});
    this.props.closeInfoSlider();
  }

  
  render(){

    return (
      
      <div className={(this.props.infoshowing === true ? styles.sliderInfo : styles.sliderInfoHide)}>
   
        <div className={styles.sliderInfoHead} style={{ whiteSpace: 'pre-wrap' }}>{this.props.headerText}</div>
        <div className={styles.sliderInfoText} >
          <div>
            {this.props.content}
            &nbsp;
            <a className={styles.sliderLinkText} href={this.props.contentlink} rel="noreferrer" target="_blank" style={(this.props.contentlinktitle !== undefined ? {}:{backgroundSize: "0px 0px"})}>
              {this.props.contentlinktitle}
            </a>
          </div>
          
          
          
        </div>
        {/* <a className={styles.sliderLinkText} href={"https://www.google.de/"} target="_blank">
          Link
        </a> */}
        
        



        <div className={styles.sliderCloseButton} onClick={this.ToggleInfo}></div>
      </div>
      
    )
  }
}

export default InfoSlider
