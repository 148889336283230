import React from 'react';
import styles from './OverlayPage.module.scss';

class OverlayPage extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      hideoverlay: false
    };
  }

  /* componentDidUpdate(prevProps) {
    if(prevProps.sceneloaded !== this.props.sceneloaded) {
      this.closeOverlay();
    }
  } */

  /* closeOverlay() {
    if(this.props.sceneloaded === true) {
      this.setState({hideoverlay: true});
    }
    else {
      console.log("not loaded yet");
    }
  } */

 /*  <div
          className={styles.descriptionContainer1}>
            <div className={styles.descriptionOne}></div>
            <div className={styles.descriptionTwo}></div>
            <div className={styles.descriptionThree}></div>
        </div> */
  
  render(){
    //onClick={()=>this.closeOverlay()}
    

    return (
      <div 
        className={styles.mainContainer}>
        <div
          className={(this.props.sceneloaded === true ? styles.overlayContainerHide : styles.overlayContainer)}
        >
          <div className={styles.overlayLogo}></div>
          <div className={styles.containerAnimation}>
            <div className={styles.dotdot1}></div>
            <div className={styles.dotdot2}></div>
            <div className={styles.dotdot3}></div>
          </div>

        </div> 

        
      </div>
    )
  }
}

export default OverlayPage
