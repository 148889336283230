import React, { useRef, useMemo, useLayoutEffect, useState, Suspense, useEffect } from 'react'
import { CompressedTextureLoader, CubeTextureLoader } from 'three'
import { Canvas, useThree, useLoader } from '@react-three/fiber'
import { FlyControls } from '@react-three/drei'
import * as THREE from 'three'
import create from 'zustand'
import { useSpring, animated } from '@react-spring/three'
import * as easings from 'd3-ease'
//import axios from 'axios';

import MenuSlider from "./MenuSlider"
import InfoSlider from "./InfoSlider"
import OverlayPage from "./OverlayPage"
import InstructionPage from "./InstructionPage"

//PERFORMANCE PROTOCOL
//0% remove texture of 3D text
//0% reduce 3d text size
//60% reduce 3d text bevel size 50->5
//15% reduce 3D text curveSegments 50->25
//10% remove sky texture
//-> other option: set opacity of all materials to 1 when loaded


//import fontBoldSource from './assets/MonumentGrotesk/Monument-Grotesk_Bold.json'

const useStore = create(set => ({
  menuoption: 0,
  imageSelection: null,
  imageInfoSelection: null,
  floatingTextContent: [],
  floatingTitleContent: [],
  imagePositons: [],
  imageSources: [],
  headerText: [],
  content: [],
  contentLinkTitle: [],
  contentLink: [],
  imgYearGroups: [],
  menuAllYears: [],
  menuCurrentYear: [],
  loadedStatus: false,
  skyTextId: 0,
  sceneRotation: 0
}))

/* var loadmanager = new THREE.LoadingManager();
loadmanager.onStart = function() {
  console.log("LOADING START!");
}
loadmanager.onLoad = function() {
  console.log("LOADING END!");
} */
function GenerateCompass() {
  const sceneRotation = useStore(state => state.sceneRotation);
  const compassRot = Math.atan2(sceneRotation[0], sceneRotation[1])*(360/Math.PI);
  //map 0 -1 0 1 0 
  return (
    <div className="compassIcon" style={{ 
                  transform: "rotate("+compassRot+"deg)"
      }}></div>
  )
}

function GenerateMenuSlider() {
  //console.log("R: GenerateMenuSlider");
  const menuoption = useStore(state => state.menuoption);
  const menuCurrentYear = useStore(state => state.menuCurrentYear);
  const menuAllYears = useStore(state => state.menuAllYears);

  function SwitchScene(id) {
    //overlay
    if(id !== menuoption) {
      useStore.setState({ loadedStatus: false });
      setTimeout(() => {
        useStore.setState({ menuoption: id });
      }, 1000);
    }
    
  }

  return (
  <MenuSlider changeMenuOption={(i) => SwitchScene(i)} currentMenuOption={menuoption}
  currentYear={menuCurrentYear}
  allYears={menuAllYears}
  ></MenuSlider>
  )
}


function GenerateFloatingText() {
  console.log("R: GenerateFloatingText");
  const menuoption = useStore(state => state.menuoption);
  const content = useStore(state => state.floatingTextContent);
  const imageSources = useStore(state => state.imageSources);
  ////const imagePositons = useStore(state => state.imagePositons);
  const imgYearGroups = useStore(state => state.imgYearGroups);
  const floatingTitleContent = useStore(state => state.floatingTitleContent);
  /* const imageSources = useStore.getState().imageSources;
  const imgYearGroups = useStore.getState().imgYearGroups;
  const floatingTitleContent = useStore.getState().floatingTitleContent;
  const content = useStore.getState().floatingTextContent; */


  const emptyContent = [];
  const filteredTitleContent = (floatingTitleContent[0] !== undefined ? floatingTitleContent[menuoption] : emptyContent);  
  const filteredContent = (content[0] !== undefined ? removeDuplicates(content[menuoption]) : emptyContent);
  const filteredImageSources = (imageSources[0] !== undefined ? imageSources[menuoption] : emptyContent);
  const filteredimgYearGroups = (imgYearGroups[0] !== undefined ? Object.values(imgYearGroups[menuoption]) : emptyContent);

  var newimagePositions = [];
  let min = -25;
  let max = 25;
  var imgPlanes = [];
  var floatingtextArray = [];
  var imgCount = filteredImageSources.length;

  var operationsCount = 0;
  function operation() {
      ++operationsCount;
      ////////////////////////////////////error
      /* if (operationsCount === imgCount) {
        //console.log("operations finished");
        //useStore.setState({imagePositons: newimagePositions});
        useStore.setState((state) => {
          if (state.imagePositons !== newimagePositions){
            //console.log(state.imagePositons);
            return {imagePositons: newimagePositions}
          }
          else { return }
        });
      } */
  }

  useEffect(() => {
    if (operationsCount === imgCount) {
      useStore.setState({imagePositons: newimagePositions});
    }
  }, [newimagePositions, operationsCount, imgCount]);
  /* useEffect(() => {
    console.log("menuoption changed");
    
  }, [menuoption]) */

  /* useEffect(() => {
    if(operationsCount === imgCount) {
      console.log(operationsCount)
      useStore.setState({ imagePositons: newimagePositions });
    }
  }, [operationsCount]); */
  
  function removeDuplicates(data) {
    return data.filter((value,index) => data.indexOf(value) === index);
  }

  var counterForID = 0; 
  var lastZpos = 0;
  for (let i = 0; i < filteredContent.length; i++){
    let groupsize = (i === 0 ? 0 : filteredimgYearGroups[i-1]);
    let newXpos = Math.floor(Math.random()*(max-min+1)+min); 
    //let newZpos = (-i*50)-25;
    let newZpos2 = lastZpos-((groupsize*25)+(groupsize === 1 ? 15 : 0))-50;
    floatingtextArray.push(<FloatingText key={"FlyText"+i} hAlign="center" position={[newXpos, -30, newZpos2]} children={filteredContent[i]} />);
    
    for (let t = 0; t < filteredimgYearGroups[i]; t++){
    
      let newImgXpos = Math.floor(Math.random()*(50-(-50)+1)+(-50));
      let newImgYpos = Math.floor(Math.random()*(-6-(-4)+1)+(-4));
      
      //let ranZDistance = Math.floor(Math.random()*(10-5+1)+5);
      newimagePositions.push(((newZpos2+10)-(t*25)-25));
      const imgsrc = filteredImageSources[counterForID];

      //TODO: change later to last tab number
      if (menuoption === 8) {
        var splitTitle = [];
        if (filteredTitleContent[0] !== undefined) {
          splitTitle = filteredTitleContent[counterForID].split('&');
          imgPlanes.push(
            <FloatingTextGroup key={"FlyTitleKey"+counterForID} groupID={counterForID} chars={splitTitle} countInGroup={t} nXpos={newXpos} nImgXpos={newImgXpos} nImgYpos={newImgYpos} nZpos2={newZpos2} count={splitTitle.length} ></FloatingTextGroup>
          );
          
        }

      }
      else {
        let maxV = 32;
        let minV = 28;
        for (let z = 0; z < imgsrc.length; z++) {
          let xAdd = Math.floor(Math.random()*(maxV-minV+1)+minV);
          let yAdd = Math.floor(Math.random()*(2+2+1)-2);
          imgPlanes.push(<ImgPlane key={"imgP " + counterForID + "-" + z} imgID={counterForID} imgSource={imgsrc[z]} position={[newXpos+newImgXpos+(xAdd*z), newImgYpos+(yAdd), (newZpos2-(t*25)-25)]} />);
        }
        //imgPlanes.push(<ImgPlane key={"imgP " + counterForID} imgID={counterForID} imgSource={imgsrc} position={[newXpos+newImgXpos, newImgYpos, (newZpos2-(t*25)-25)]} />);
        //imgPlanes.push(<ImgPlane key={"imgP " + counterForID} imgID={counterForID} imgSource={imgsrc} position={[newXpos+newImgXpos+20, newImgYpos+10, (newZpos2-(t*25)-25)]} />);
      }

      operation();
      counterForID++;
    }
    lastZpos = newZpos2;
  }
  //console.log(counterForID);


  return (
    <>
      {floatingtextArray}
      {imgPlanes}
    </>
  )
}


//TOD0: Filter BW Hover?

function ImageInfoSelection(id) {
  useStore.setState({ imageInfoSelection: id});
  //console.log("Image " + id + " selected!");
}

function ImgPlane(props) {
    const mesh = useRef();
    const source = "/content-images/" + props.imgSource;
    //const greysource = "/grey-texture.jpeg";
    const texture = useLoader(THREE.TextureLoader, source);
    useEffect(() => {
      if (props.imgID === 0){
        setTimeout(() => {
          useStore.setState({ loadedStatus: true });
        }, 500);
      }
    }, [texture, props.imgID])
    //const greyTexture = useLoader(THREE.TextureLoader, greysource);
    const [active, setActive] = useState(false);
    const [selected, setSelection] = useState(false);
    const propSpring = useSpring({
      rot: active ? [0,0,0] : [-Math.PI/2,0,0],
      matColor: selected ? "#C7FF25" : "white",
      config: { mass: 5, tension: 400, friction: 50, precision: 0.0001 }
    });
    const { x } = useSpring({
      from: { x: 0 },
      x: selected ? 1 : 0,
      config: { duration: 250, easing: easings.easeQuadInOut },
    })
    //TODO: add usespring hover
    //const imageSelection = useStore.subscribe((imageSelection) => setImageActive(imageSelection), state => state.imageSelection)
    useEffect(() => 
    useStore.subscribe(
      imageSelection => {
        if((imageSelection-10) === props.position[2] && !active) {
          setActive(true);
          //console.log("active :"+props.position[2], imageSelection);
          //console.log(props.imgID + " active!");
          useStore.setState({menuCurrentYear: props.imgID});
        }
        else if ((imageSelection-10) === props.position[2] && active){
          //nothing
        }
        else {
          setActive(false); 
        }
      },
      state => state.imageSelection
    ), []);

    /* function setImageActive(imSel) {
      //console.log(imSel);
      if((imSel-10) === props.position[2] && !active) {
        setActive(true);
        //console.log(props.imgID + " active!");
        useStore.setState({menuCurrentYear: props.imgID});
      }
      else if ((imSel-10) === props.position[2] && active){
        //nothing
      }
      else {
        setActive(false);
      }
    } */

    useEffect(() => {

    }, []);

    function setSelectionCall() {
      if(active) {
        setSelection(!selected);
        ImageInfoSelection(props.imgID);
      }
    };
    //<planeBufferGeometry attach="geometry" args={[9, 9*imgHeightRatio]} />
    const imgHeightRatio = texture.image.width/texture.image.height;
    return (
      <animated.mesh
      {...props}
      ref={mesh}
      scale={
        x.to({
        range: [0, 0.5, 1],
        output: [1, 0.9, 1],
        })
      }
      rotation={propSpring.rot}
      onClick={() => setSelectionCall()}

      >
        <boxBufferGeometry attach="geometry" args={[12*imgHeightRatio, 12, 0.5]}/>
        <meshBasicMaterial attachArray="material" color={"white"} />
        <meshBasicMaterial attachArray="material" color={"white"} />
        <meshBasicMaterial attachArray="material" color={"white"} />
        <meshBasicMaterial attachArray="material" color={"white"} />
        <meshBasicMaterial attachArray="material" color={"white"} map={texture} />
        <meshBasicMaterial attachArray="material" color={"white"} map={texture} />
      </animated.mesh>
    )
  //imageSelection();
}

function FloatingTextGroup(props) {
  var groupedTitles = [];
  const groupPosition = [props.nXpos+props.nImgXpos, props.nImgYpos, (props.nZpos2-(props.countInGroup*25)-25)];
  const ref = useRef()
  const [active, setActive] = useState(false);
  const [selected, setSelection] = useState(false);
  const propSpring = useSpring({
    rot: active ? [Math.PI/2,0,0] : [0,0,0],
    matColor: selected ? "#C7FF25" : "white",
    config: { mass: 5, tension: 400, friction: 50, precision: 0.0001 }
  });
  const { x } = useSpring({
    from: { x: 0 },
    x: selected ? 1 : 0,
    config: { duration: 250, easing: easings.easeQuadInOut },
  })

  const imageSelection = useStore.subscribe((imageSelection) => setImageActive(imageSelection), state => state.imageSelection)

  function setImageActive(imSel) {
    if((imSel-10) === groupPosition[2]) {
      setActive(true);
      //console.log(props.id + " active!");
      
    }
    else {
      setActive(false);
    }
  }
  const zSpacing = (props.count === 2 ? 1 : 3);
  for(let i=0; i < props.count; i++) {
    groupedTitles.push(
      <FloatingTitle key={props.groupID+"-"+i} idNum={props.groupID+"-"+i} hAlign="center" position={[0, 0, (4*i)-zSpacing]} children={props.chars[i]} />
    )
  }

  function setSelectionCall() {
    if(active) {
      setSelection(!selected);
      ImageInfoSelection(props.groupID);
    }
  };
  
  return (
    <animated.group
      ref={ref}
      rotation={propSpring.rot}
      onClick={() => setSelectionCall()}
      position={groupPosition}
      scale={
        x.to({
        range: [0, 0.5, 1],
        output: [1, 0.9, 1],
        })
      }
    >
      {groupedTitles}
    </animated.group>
  )
}

function FloatingText({ children, vAlign = 'center', hAlign = 'center', size = 1, color = '#000000', ...props }) {
  const mesh = useRef();
  const font = useLoader(THREE.FontLoader, '/Monument-Grotesk_Bold.json')
  const config = useMemo(
    () => ({ font, size: 50, height: 2.5, curveSegments: 25, bevelEnabled: true, bevelSize: 0.1, bevelThickness: 0.1, bevelOffset: 0, bevelSegments: 5}),
    [font]
  )
  useLayoutEffect(() => {
    const size = new THREE.Vector3()
    mesh.current.geometry.computeBoundingBox()
    mesh.current.geometry.boundingBox.getSize(size)
    mesh.current.position.x = hAlign === 'center' ? ((-size.x / 2)+props.position[0]) : hAlign === 'right' ? 0 : ((-size.x)+props.position[0])
    //mesh.current.position.y = vAlign === 'center' ? -size.y / 2 : vAlign === 'top' ? 0 : -size.y
  }, [children, hAlign, props.position])

  ////Material Stuff
  /* const { scene, gl } = useThree();
  const cubeRenderTarget = new THREE.WebGLCubeRenderTarget(256, {
    format: THREE.RGBFormat,
    generateMipmaps: true,
    minFilter: THREE.LinearMipmapLinearFilter,
  });
  const cubeCamera = new THREE.CubeCamera(1, 1000, cubeRenderTarget);
  cubeCamera.position.set(0, 0, 0);
  scene.add(cubeCamera);
  useFrame(() => cubeCamera.update(gl, scene)); */
  ////vorheriges html material
  /*{ <meshBasicMaterial
      attach="material"
      envMap={cubeCamera.renderTarget.texture}
      reflectivity={0.9}
      color="white"
      roughness={0.1}
      metalness={1.0}/> }*/
  ////
  const map = useLoader(THREE.TextureLoader, '/12717.jpg')
  //useEffect(() => console.log("CENTURIES LOADED"), [map])

  return(
    <mesh
    {...props}
    ref={mesh}
    rotation={new THREE.Euler((-Math.PI/2), 0, 0)}
    >
      <textBufferGeometry args={[children, config]} />
      <meshMatcapMaterial
      //transparent="true"
      //opacity="0.9"
      attach="material"
      color="white"
      matcap={map}
      />

    </mesh>
  )
}

function FloatingTitle({ children, vAlign = 'center', hAlign = 'center', size = 1, color = '#000000', ...props }) {
  const mesh = useRef();
  const font = useLoader(THREE.FontLoader, '/Monument-Grotesk_Bold.json')
  const config = useMemo(
    () => ({ font, size: 3, height: 0.3, curveSegments: 5}),
    [font]
  )
  const map = useLoader(THREE.TextureLoader, '/12717.jpg')

  useEffect(() => {
    if (props.idNum === "0-0"){
      setTimeout(() => {
        useStore.setState({ loadedStatus: true });
      }, 500);
    }
  }, [map, props.idNum])

  useLayoutEffect(() => {
    const size = new THREE.Vector3()
    mesh.current.geometry.computeBoundingBox()
    mesh.current.geometry.boundingBox.getSize(size)
    mesh.current.position.x = hAlign === 'center' ? ((-size.x / 2)+props.position[0]) : hAlign === 'right' ? 0 : ((-size.x)+props.position[0])
    //mesh.current.position.y = vAlign === 'center' ? -size.y / 2 : vAlign === 'top' ? 0 : -size.y
  }, [children, hAlign, props.position])
  
  return(
      <mesh
      {...props}
      ref={mesh}
      rotation={new THREE.Euler((-Math.PI/2), 0, 0)}
      >
        <textBufferGeometry args={[children, config]} />
        <meshMatcapMaterial
        attach="material"
        color="white"
        matcap={map}
        />

      </mesh>
  ) 
}

function SkyBox(textID) {
    console.log("R: Skybox");
    const { scene } = useThree();
    const loader = new CubeTextureLoader();
    //const ranNum = Math.floor(Math.random() * ((5) - (0) + 1)) + (0);
    //0d 1d 4d 10d /14 17d /18 20d
    //zu bunt/pixelig: /11 /7 /6
    //zu hell: /13
    const skyTextId = useStore(state => state.skyTextId);
    //console.log("sky id: "+skyTextId);
    const ranNum = skyTextId;
    const texture = loader.load([
        "/skytextures/" + ranNum + "/px.png",
        "/skytextures/" + ranNum + "/nx.png",
        "/skytextures/" + ranNum + "/py.png",
        "/skytextures/" + ranNum + "/ny.png",
        "/skytextures/" + ranNum + "/pz.png",
        "/skytextures/" + ranNum + "/nz.png"
    ]);
    //useEffect(() => console.log("SKYBOX LOADED"), [texture])
    scene.background = texture;
    return null;
}

////////////////////////////////////////////ineffizient
function cameraMovement(campos, imgposArr, camrot) {
  var currImgSel = 0;
  if (currImgSel !== getClosest(campos.z, imgposArr)) {
    currImgSel = getClosest(campos.z, imgposArr)
    useStore.setState({ imageSelection: currImgSel, sceneRotation: [camrot.y, camrot.w] })
    //console.log(currImgSel)
  }
}
function getClosest(search, arr) {
  var closest = null;
  for (const value of arr) {
    //if (closest === null || (Math.abs((search-10) - closest) > Math.abs(value - (search-10)))) {
    if (closest === null || search <= closest) {
      closest = value;
    }
  }
  //console.log("closest: "+closest, "search: "+search);
  return closest;
}

function GenerateInfoSlider() {
  //console.log("R: GenerateInfoSlider");
  const menuoption = useStore(state => state.menuoption);
  const menuCurrentYear = useStore(state => state.menuCurrentYear);
  const imageInfoSelection = useStore(state => state.imageInfoSelection);
  const headerText = useStore(state => state.headerText);
  const content = useStore(state => state.content);
  const linktitle = useStore(state => state.contentLinkTitle);
  const link = useStore(state => state.contentLink);
  const emptyContent = [0,0,0];
  const filteredHeaderText = (headerText[0] !== undefined ? headerText[menuoption] : emptyContent);
  const filterContent = (content[0] !== undefined ? content[menuoption] : emptyContent);
  const filteredLinkTitle = (linktitle[0] !== undefined ? linktitle[menuoption] : emptyContent);
  const filteredLink = (link[0] !== undefined ? link[menuoption] : emptyContent);
  const [showInfo, setShowInfo] = useState(false);

  //Anklicken
  useEffect(() => {
    let mounted = true;
    if (imageInfoSelection !== null && mounted) {
      setShowInfo(true);
    }
    return function cleanup() {
      mounted = false;
    }
  }, [imageInfoSelection]);

  //Schließen beim Weiterfliegen
  useEffect(() => {
    let mounted = true;
    if (menuCurrentYear !== null && showInfo && mounted) {
      setShowInfo(false);
      useStore.setState({ imageInfoSelection: null });
      /* setTimeout(() => {
        useStore.setState({ imageInfoSelection: null });
      }, 500); */
    }
    return function cleanup() {
      mounted = false;
    }
  }, [menuCurrentYear]);

  //close when menuoption changes
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      CloseInfoSlider();
    }
    return function cleanup() {
      mounted = false;
    }
  }, [menuoption]);

  //Schließen mit x 
  function CloseInfoSlider() {
    setShowInfo(false);
    //use animation time of slider -> 500ms
    setTimeout(() => {
      useStore.setState({ imageInfoSelection: null });
    }, 500);
  }

  return <InfoSlider headerText={filteredHeaderText[imageInfoSelection]} infoshowing={showInfo} closeInfoSlider={CloseInfoSlider} content={filterContent[imageInfoSelection]} contentlinktitle={filteredLinkTitle[imageInfoSelection]} contentlink={filteredLink[imageInfoSelection]}></InfoSlider>

}

//TODO: change controls in script
function GenerateFlyControls() {
  //console.log("R: GenerateFlyControls");
  const menuoption = useStore(state => state.menuoption);
  const flyRef = useRef();
  const imagePositons = useStore(state => state.imagePositons);
  //const imagePositons = [10,40,60];
  //console.log(imagePositons);

  useEffect(() => {
    flyRef.current.object.position.x = 0;
    flyRef.current.object.position.y = 0;
    flyRef.current.object.position.z = 0;
    flyRef.current.object.quaternion.y = 0;
  }, [menuoption]);

  useEffect(() => {
    
    if(flyRef.current && imagePositons.length !== 0){
      //const imagePositons = useStore.getState().imagePositons;
      //console.log("imgposApp: "+imagePositons);
      const flycontrol = flyRef.current;
      const controlPos = flyRef.current.object.position;
      const controlRot = flyRef.current.object.quaternion; 
      flycontrol.addEventListener( 'change', function(){
        cameraMovement(controlPos,imagePositons,controlRot);
        //console.log(flyRef.current.object.quaternion);
      } );
      return () => flycontrol.removeEventListener( 'change', function(){cameraMovement(controlPos,imagePositons)} );
    }
  })

  return <FlyControls
  ref={flyRef}
  change={(e)=>console.log(e)}
  movementSpeed={30}
  rollSpeed={0.9}
  dragToLook={true}/>
}

function LoaderOverlay() {
  console.log("R: LoaderOverlay");
  //count all objs
  const loadedStatus = useStore(state => state.loadedStatus);
  //console.log(loadedStatus);
  /* if (loadedCount !== 0) {
    console.log("DEACTIVATE OVERLAY");
    //setLoaded(true);
    loading = true;
  } */

  /* useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []); */

  return <OverlayPage sceneloaded={loadedStatus}></OverlayPage>
}

export default function App() {
  //console.log(window.performance);
  console.log("R: App");
  //const [data, setData] = useState(null);
  //const [textHeader, setTH] = useState([]);
  //const menuoption = useStore(state => state.menuoption);
  
  //console.log("RENDER: APP");

  useEffect(() => {
    var urlArray = [];
    const ranNum = Math.floor(Math.random() * ((20) - (0) + 1)) + (0);
    useStore.setState({ skyTextId: ranNum });
    //CHANGE BACK TO max 8
    /* for (let i = 5; i < 8; i++) {
      urlArray.push("https://spreadsheets.google.com/feeds/list/1qjpHN5qSC0gVtT74aU6ZhjQD9LtqKs_v1j46SfuH4Xs/" + (i+1) + "/public/full?alt=json");
    } */
    
    //später api beschränken auf webseite
    //https://sheets.googleapis.com/v4/spreadsheets/1qjpHN5qSC0gVtT74aU6ZhjQD9LtqKs_v1j46SfuH4Xs/values/Ehrungen?alt=json&key=AIzaSyBS1QjCg08t-_IQYf5GaOsFW80l0BfSqBs
    let one = "https://sheets.googleapis.com/v4/spreadsheets/1qjpHN5qSC0gVtT74aU6ZhjQD9LtqKs_v1j46SfuH4Xs/values/Zeitreise?alt=json&key=AIzaSyBS1QjCg08t-_IQYf5GaOsFW80l0BfSqBs";
    let two = "https://sheets.googleapis.com/v4/spreadsheets/1qjpHN5qSC0gVtT74aU6ZhjQD9LtqKs_v1j46SfuH4Xs/values/Jahrzehnte?alt=json&key=AIzaSyBS1QjCg08t-_IQYf5GaOsFW80l0BfSqBs";
    let three = "https://sheets.googleapis.com/v4/spreadsheets/1qjpHN5qSC0gVtT74aU6ZhjQD9LtqKs_v1j46SfuH4Xs/values/FHWS?alt=json&key=AIzaSyBS1QjCg08t-_IQYf5GaOsFW80l0BfSqBs";
    let four = "https://sheets.googleapis.com/v4/spreadsheets/1qjpHN5qSC0gVtT74aU6ZhjQD9LtqKs_v1j46SfuH4Xs/values/Fakultaeten?alt=json&key=AIzaSyBS1QjCg08t-_IQYf5GaOsFW80l0BfSqBs";
    let five = "https://sheets.googleapis.com/v4/spreadsheets/1qjpHN5qSC0gVtT74aU6ZhjQD9LtqKs_v1j46SfuH4Xs/values/Internationales?alt=json&key=AIzaSyBS1QjCg08t-_IQYf5GaOsFW80l0BfSqBs";
    let six = "https://sheets.googleapis.com/v4/spreadsheets/1qjpHN5qSC0gVtT74aU6ZhjQD9LtqKs_v1j46SfuH4Xs/values/Gebaeude?alt=json&key=AIzaSyBS1QjCg08t-_IQYf5GaOsFW80l0BfSqBs";
    let seven = "https://sheets.googleapis.com/v4/spreadsheets/1qjpHN5qSC0gVtT74aU6ZhjQD9LtqKs_v1j46SfuH4Xs/values/Forschung?alt=json&key=AIzaSyBS1QjCg08t-_IQYf5GaOsFW80l0BfSqBs";
    let eight = "https://sheets.googleapis.com/v4/spreadsheets/1qjpHN5qSC0gVtT74aU6ZhjQD9LtqKs_v1j46SfuH4Xs/values/Praesidenten?alt=json&key=AIzaSyBS1QjCg08t-_IQYf5GaOsFW80l0BfSqBs";
    let nine = "https://sheets.googleapis.com/v4/spreadsheets/1qjpHN5qSC0gVtT74aU6ZhjQD9LtqKs_v1j46SfuH4Xs/values/Ehrungen?alt=json&key=AIzaSyBS1QjCg08t-_IQYf5GaOsFW80l0BfSqBs";

    urlArray.push(one, two, three, four, five, six, seven, eight, nine);

    //axios stuff
    /* const requestOne = axios.get(one);
    const requestTwo = axios.get(two);
    const requestThree = axios.get(three);
    
    axios
      .all([requestOne, requestTwo, requestThree])
      .then(
        axios.spread((...responses) => {
          const responseOne = responses[0];
          const responseTwo = responses[1];
          const responesThree = responses[2];

          // use/access the results
          console.log(responseOne, responseTwo, responesThree);
        })
      )
      .catch(errors => {
        // react on errors.
        console.log("axios fail");
        console.error(errors);
    }); */

    //fetching :
    async function fetchData2() {
      try {
        await Promise.all(
          urlArray.map(async (url) => {
            /* await fetch(url)
              .then(res => res.json()) */
            const fetchdata = await fetch(url);
            return await fetchdata.json();
              
          })
        ).then(res => {
          console.log(res);
          composeData(res);
        });
      }
      catch (error) {
        console.log("ERROR: ", error);
      }
    }
    fetchData2();

    function checkImageSrcs(a,b,c,d,e) {
      var newImgSrcsArray = [];
      newImgSrcsArray.push(a);
      if (b !== undefined && b !== "") {
        newImgSrcsArray.push(b);
        if (c !== undefined && c !== "") {
          newImgSrcsArray.push(c);
          if (d !== undefined && d !== "") {
            newImgSrcsArray.push(d);
            if (e !== undefined && e !== "") {
              newImgSrcsArray.push(e);
            }
          }
        }
      }

      return newImgSrcsArray
    }
    
    //data sorting :
    const composeData = (res) => {
      //NICE
      var countByGroup = function(val) {
        return val.reduce(function(rv, x) {
          rv[x] = (rv[x] || 0)+1;
          return rv;
        }, {});
      };

      var yearsArray = [];
      var imgsrcsArray = [];
      var headertextArray = [];
      var contentArray = [];
      var contentLinkTitleArray = [];
      var contentLinkArray = [];
      var imgCountPerGroup = [];
      var titleArray = [];
      var decadeArray = [];
      //console.log(res[0].values[1][1]);
      for(let t = 0, n=res.length; t < n; t++) {
        var years = [];
        var imgsrcs = [];
        var headertext = [];
        var title = [];
        var content = [];
        var contentLinkTitle = [];
        var contentLink = [];
        var decades = [];

        //0 - Zeitreise
        //10 15 - Jahrzehnte
        //2 - Die FHWS
        //20 29 - Fakultäten
        //4 - Internationales
        //30 31 - Gebäude
        //6 - Forschung
        //7 - Präsidenten
        //8 - Ehrungen

        //tab number -> jahrzehnte
        if(t === 1) {

          for (let x = 0; x < 7; x++) {
            headertext = [];
            content = [];
            contentLinkTitle = [];
            contentLink = [];
            title = [];
            years = [];
            decades = [];
            imgsrcs = [];

            for (let i = 1; i < res[t].values.length; i++) {

              if (x === 0 && res[t].values[i][7] === "71-79") {
                headertext.push(res[t].values[i][1]);
                content.push(res[t].values[i][2]);
                title.push(res[t].values[i][3]);
                years.push(res[t].values[i][4]);
                decades.push(res[t].values[i][5]);
                imgsrcs.push(checkImageSrcs(
                  res[t].values[i][6],
                  res[t].values[i][10],
                  res[t].values[i][11],
                  res[t].values[i][12],
                  res[t].values[i][13]
                ));
                contentLinkTitle.push(res[t].values[i][8]);
                contentLink.push(res[t].values[i][9]);
              }
              else if (x === 1 && res[t].values[i][7] === "80-89") {
                headertext.push(res[t].values[i][1]);
                content.push(res[t].values[i][2]);
                title.push(res[t].values[i][3]);
                years.push(res[t].values[i][4]);
                decades.push(res[t].values[i][5]);
                imgsrcs.push(checkImageSrcs(
                  res[t].values[i][6],
                  res[t].values[i][10],
                  res[t].values[i][11],
                  res[t].values[i][12],
                  res[t].values[i][13]
                ));
                contentLinkTitle.push(res[t].values[i][8]);
                contentLink.push(res[t].values[i][9]);
              }
              else if (x === 2 && res[t].values[i][7] === "90-99") {
                headertext.push(res[t].values[i][1]);
                content.push(res[t].values[i][2]);
                title.push(res[t].values[i][3]);
                years.push(res[t].values[i][4]);
                decades.push(res[t].values[i][5]);
                imgsrcs.push(checkImageSrcs(
                  res[t].values[i][6],
                  res[t].values[i][10],
                  res[t].values[i][11],
                  res[t].values[i][12],
                  res[t].values[i][13]
                ));
                contentLinkTitle.push(res[t].values[i][8]);
                contentLink.push(res[t].values[i][9]);
              }
              else if (x === 3 && res[t].values[i][7] === "00-09") {
                headertext.push(res[t].values[i][1]);
                content.push(res[t].values[i][2]);
                title.push(res[t].values[i][3]);
                years.push(res[t].values[i][4]);
                decades.push(res[t].values[i][5]);
                imgsrcs.push(checkImageSrcs(
                  res[t].values[i][6],
                  res[t].values[i][10],
                  res[t].values[i][11],
                  res[t].values[i][12],
                  res[t].values[i][13]
                ));
                contentLinkTitle.push(res[t].values[i][8]);
                contentLink.push(res[t].values[i][9]);
              }
              else if (x === 4 && res[t].values[i][7] === "10-19") {
                headertext.push(res[t].values[i][1]);
                content.push(res[t].values[i][2]);
                title.push(res[t].values[i][3]);
                years.push(res[t].values[i][4]);
                decades.push(res[t].values[i][5]);
                imgsrcs.push(checkImageSrcs(
                  res[t].values[i][6],
                  res[t].values[i][10],
                  res[t].values[i][11],
                  res[t].values[i][12],
                  res[t].values[i][13]
                ));
                contentLinkTitle.push(res[t].values[i][8]);
                contentLink.push(res[t].values[i][9]);
              }
              else if (x === 5 && res[t].values[i][7] === "20-21") {
                headertext.push(res[t].values[i][1]);
                content.push(res[t].values[i][2]);
                title.push(res[t].values[i][3]);
                years.push(res[t].values[i][4]);
                decades.push(res[t].values[i][5]);
                imgsrcs.push(checkImageSrcs(
                  res[t].values[i][6],
                  res[t].values[i][10],
                  res[t].values[i][11],
                  res[t].values[i][12],
                  res[t].values[i][13]
                ));
                contentLinkTitle.push(res[t].values[i][8]);
                contentLink.push(res[t].values[i][9]);
              }
              else {
              }
            }
            headertextArray[10+x] = headertext;
            contentArray[10+x] = content;
            contentLinkTitleArray[10+x] = contentLinkTitle;
            contentLinkArray[10+x] = contentLink;
            titleArray[10+x] = title;
            yearsArray[10+x] = years;
            decadeArray[10+x] = decades;
            imgsrcsArray[10+x] = imgsrcs;
            imgCountPerGroup[10+x] = countByGroup(decades);
          }
        }

        //tab number -> fakultäten
        if(t === 3) {

          //x = different IDs
          for (let x = 0; x < 6; x++) {
            headertext = [];
            content = [];
            contentLinkTitle = [];
            contentLink = [];
            title = [];
            years = [];
            decades = [];
            imgsrcs = [];

            for (let i = 1; i < res[t].values.length; i++) {
              
              if (x === 0 && res[t].values[i][7] === "FANG") {
                headertext.push(res[t].values[i][1]);
                content.push(res[t].values[i][2]);
                title.push(res[t].values[i][3]);
                years.push(res[t].values[i][4]);
                decades.push(res[t].values[i][5]);
                imgsrcs.push(checkImageSrcs(
                  res[t].values[i][6],
                  res[t].values[i][10],
                  res[t].values[i][11],
                  res[t].values[i][12],
                  res[t].values[i][13]
                ));
                contentLinkTitle.push(res[t].values[i][8]);
                contentLink.push(res[t].values[i][9]);
              }
              else if (x === 1 && res[t].values[i][7] === "FAB") {
                headertext.push(res[t].values[i][1]);
                content.push(res[t].values[i][2]);
                title.push(res[t].values[i][3]);
                years.push(res[t].values[i][4]);
                decades.push(res[t].values[i][5]);
                imgsrcs.push(checkImageSrcs(
                  res[t].values[i][6],
                  res[t].values[i][10],
                  res[t].values[i][11],
                  res[t].values[i][12],
                  res[t].values[i][13]
                ));
                contentLinkTitle.push(res[t].values[i][8]);
                contentLink.push(res[t].values[i][9]);
              }
              else if (x === 2 && res[t].values[i][7] === "FKV") {
                headertext.push(res[t].values[i][1]);
                content.push(res[t].values[i][2]);
                title.push(res[t].values[i][3]);
                years.push(res[t].values[i][4]);
                decades.push(res[t].values[i][5]);
                imgsrcs.push(checkImageSrcs(
                  res[t].values[i][6],
                  res[t].values[i][10],
                  res[t].values[i][11],
                  res[t].values[i][12],
                  res[t].values[i][13]
                ));
                contentLinkTitle.push(res[t].values[i][8]);
                contentLink.push(res[t].values[i][9]);
              }
              else if (x === 3 && res[t].values[i][7] === "FWI") {
                headertext.push(res[t].values[i][1]);
                content.push(res[t].values[i][2]);
                title.push(res[t].values[i][3]);
                years.push(res[t].values[i][4]);
                decades.push(res[t].values[i][5]);
                imgsrcs.push(checkImageSrcs(
                  res[t].values[i][6],
                  res[t].values[i][10],
                  res[t].values[i][11],
                  res[t].values[i][12],
                  res[t].values[i][13]
                ));
                contentLinkTitle.push(res[t].values[i][8]);
                contentLink.push(res[t].values[i][9]);
              }
              else if (x === 4 && res[t].values[i][7] === "FWiWi") {
                headertext.push(res[t].values[i][1]);
                content.push(res[t].values[i][2]);
                title.push(res[t].values[i][3]);
                years.push(res[t].values[i][4]);
                decades.push(res[t].values[i][5]);
                imgsrcs.push(checkImageSrcs(
                  res[t].values[i][6],
                  res[t].values[i][10],
                  res[t].values[i][11],
                  res[t].values[i][12],
                  res[t].values[i][13]
                ));
                contentLinkTitle.push(res[t].values[i][8]);
                contentLink.push(res[t].values[i][9]);
              }
              else if (x === 5 && res[t].values[i][7] === "CWS") {
                headertext.push(res[t].values[i][1]);
                content.push(res[t].values[i][2]);
                title.push(res[t].values[i][3]);
                years.push(res[t].values[i][4]);
                decades.push(res[t].values[i][5]);
                imgsrcs.push(checkImageSrcs(
                  res[t].values[i][6],
                  res[t].values[i][10],
                  res[t].values[i][11],
                  res[t].values[i][12],
                  res[t].values[i][13]
                ));
                contentLinkTitle.push(res[t].values[i][8]);
                contentLink.push(res[t].values[i][9]);
              }
              else {
              }
            }
          
            headertextArray[20+x] = headertext;
            contentArray[20+x] = content;
            contentLinkTitleArray[20+x] = contentLinkTitle;
            contentLinkArray[20+x] = contentLink;
            titleArray[20+x] = title;
            yearsArray[20+x] = years;
            decadeArray[20+x] = decades;
            imgsrcsArray[20+x] = imgsrcs;
            imgCountPerGroup[20+x] = countByGroup(decades);
          }
        }

        //tab number -> gebäude
        else if(t === 5) {
          //console.log("4!");

          //x as number of different IDs
          for (let x = 0; x < 2; x++) {
            headertext = [];
            content = [];
            contentLinkTitle = [];
            contentLink = [];
            title = [];
            years = [];
            decades = [];
            imgsrcs = [];

            for (let i = 1; i < res[t].values.length; i++) {
              if (x === 0 && res[t].values[i][7] === "WUE") {
                headertext.push(res[t].values[i][1]);
                content.push(res[t].values[i][2]);
                title.push(res[t].values[i][3]);
                years.push(res[t].values[i][4]);
                decades.push(res[t].values[i][5]);
                imgsrcs.push(checkImageSrcs(
                  res[t].values[i][6],
                  res[t].values[i][10],
                  res[t].values[i][11],
                  res[t].values[i][12],
                  res[t].values[i][13]
                ));
                contentLinkTitle.push(res[t].values[i][8]);
                contentLink.push(res[t].values[i][9]);
              }
              else if (x === 1 && res[t].values[i][7] === "SW") {
                headertext.push(res[t].values[i][1]);
                content.push(res[t].values[i][2]);
                title.push(res[t].values[i][3]);
                years.push(res[t].values[i][4]);
                decades.push(res[t].values[i][5]);
                imgsrcs.push(checkImageSrcs(
                  res[t].values[i][6],
                  res[t].values[i][10],
                  res[t].values[i][11],
                  res[t].values[i][12],
                  res[t].values[i][13]
                ));
                contentLinkTitle.push(res[t].values[i][8]);
                contentLink.push(res[t].values[i][9]);
              }
              else {
              }
            }
          
            headertextArray[30+x] = headertext;
            contentArray[30+x] = content;
            contentLinkTitleArray[30+x] = contentLinkTitle;
            contentLinkArray[30+x] = contentLink;
            titleArray[30+x] = title;
            yearsArray[30+x] = years;
            decadeArray[30+x] = decades;
            imgsrcsArray[30+x] = imgsrcs;
            imgCountPerGroup[30+x] = countByGroup(decades);

          }
        }

        else {
          //rows (starting at 2nd row)
          for (let i = 1; i < res[t].values.length; i++) {
            headertext[i-1] = res[t].values[i][1];
            content[i-1] = res[t].values[i][2];
            title[i-1] = res[t].values[i][3];
            years[i-1] = res[t].values[i][4];
            decades[i-1] = res[t].values[i][5];
            imgsrcs[i-1] = checkImageSrcs(
              res[t].values[i][6],
              res[t].values[i][10],
              res[t].values[i][11],
              res[t].values[i][12],
              res[t].values[i][13]
            );
            contentLinkTitle[i-1] = res[t].values[i][8];
            contentLink[i-1] = res[t].values[i][9];
          }
          headertextArray[t] = headertext;
          contentArray[t] = content;
          contentLinkTitleArray[t] = contentLinkTitle;
          contentLinkArray[t] = contentLink;
          titleArray[t] = title;
          yearsArray[t] = years;
          decadeArray[t] = decades;
          imgsrcsArray[t] = imgsrcs;
          imgCountPerGroup[t] = countByGroup(decades);
          //if(t===2){console.log(imgsrcs);}
          
          /* headertextArray.push(headertext);
          contentArray.push(content);
          titleArray.push(title);
          yearsArray.push(years);
          decadeArray.push(decades);
          imgsrcsArray.push(imgsrcs);
          imgCountPerGroup.push(countByGroup(decades)); */
          

          /* for (let i = 0; i < res[t].feed.entry.length; i++) {
            years[i] = res[t].feed.entry[i].gsx$jahrzehnte.$t;
            imgsrcs[i] = res[t].feed.entry[i].gsx$bild1.$t;
            headertext[i] = res[t].feed.entry[i].gsx$head.$t;
            title[i] = res[t].feed.entry[i].gsx$titel.$t;
            content[i] = res[t].feed.entry[i].gsx$inhalt.$t;
          }
          yearsArray.push(years);
          titleArray.push(title);
          imgsrcsArray.push(imgsrcs);
          headertextArray.push(headertext);
          contentArray.push(content);
          imgCountPerGroup.push(countByGroup(years));
          */
        }
      } 
      useStore.setState({ floatingTitleContent: titleArray, imgYearGroups: imgCountPerGroup, menuAllYears: yearsArray, floatingTextContent: decadeArray, imageSources: imgsrcsArray, headerText: headertextArray, content: contentArray, contentLinkTitle: contentLinkTitleArray, contentLink: contentLinkArray});

    
      /* var groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };

      var testarray = [];
      for (let i = 0; i < res[0].feed.entry.length; i++) {
        testarray[i] = res[0].feed.entry[i].gsx$jahrzehnte;
      }
      var groubedByTeam=groupBy(testarray, '$t');
      console.log(groubedByTeam); */
    };

    /* var yearsArray = [];
    var imgsrcsArray = [];
    var headertextArray = [];
    //const url = "https://spreadsheets.google.com/feeds/list/1qjpHN5qSC0gVtT74aU6ZhjQD9LtqKs_v1j46SfuH4Xs/1/public/full?alt=json";
    const fetchData = async (url, id) => {
      try {
          const response = await fetch(url);
          const json = await response.json();
          console.log(json);
          //console.log(json.feed.entry[0]["gsx$bild"+1].$t);
          setData(json);
          //TODo: check if years
          if(id === 1) {
            var years = [];
            var imgsrcs = [];
            var headertext = [];
            for (let i = 0; i < json.feed.entry.length; i++) {
              years[i] = json.feed.entry[i].gsx$jahrzehnte.$t;

              var imgsrcRows = [];
              for (let t = 1; t < 2; t++) {
                const imgsrc = json.feed.entry[i]["gsx$bild"+t].$t;
                if (imgsrc !== "") {
                  imgsrcRows.push(imgsrc);
                }
              }
              imgsrcs.push(imgsrcRows);

              headertext[i] = json.feed.entry[i].gsx$head.$t;
            }
            //useStore.setState({ floatingTextContent: years, imageSources: imgsrcs, headerText: headertext});
            yearsArray.push(years);
            imgsrcsArray.push(imgsrcs);
            headertextArray.push(headertext);
            //console.log("id1: "+yearsArray);
            //useStore.setState({ floatingTextContent: yearsArray, imageSources: imgsrcsArray, headerText: headertextArray});

          }
          else if(id === 0) {
            var years = [];
            var imgsrcs = [];
            var headertext = [];
            for (let i = 0; i < json.feed.entry.length; i++) {
              years[i] = json.feed.entry[i].gsx$jahrzehnte.$t;

              var imgsrcRows = [];
              for (let t = 1; t < 2; t++) {
                const imgsrc = json.feed.entry[i]["gsx$bild"+t].$t;
                if (imgsrc !== "") {
                  imgsrcRows.push(imgsrc);
                }
              }
              imgsrcs.push(imgsrcRows);
              headertext[i] = json.feed.entry[i].gsx$head.$t;
            }
            yearsArray.push(years);
            imgsrcsArray.push(imgsrcs);
            headertextArray.push(headertext);
            //console.log("id0: "+yearsArray);
            useStore.setState({ floatingTextContent: yearsArray, imageSources: imgsrcsArray, headerText: headertextArray});
          }
          
      } catch (error) {
          console.log("ERROR at SHEET " + id + ":", error);
      }
    };
    
    urlArray.forEach(function (url, i) {
      console.log(url);
      fetchData(url, i);
    }); */
    
  }, []);

  /*
  const flyRef = useRef();
  //const imgpos = useStore(state => state.imagePositons)

   useEffect(() => {
    
    if(flyRef.current){
      const imagePositons = useStore.getState().imagePositons;
      //console.log("imgposApp: "+imagePositons);
      const flycontrol = flyRef.current;
      const controlPos = flyRef.current.object.position;
      flycontrol.addEventListener( 'change', function(){cameraMovement(controlPos,imagePositons)} );
      return () => flycontrol.removeEventListener( 'change', function(){cameraMovement(controlPos,imagePositons)} );
    }
  })
 */
  
  
  //TODO: rotate camera down a bit
  //<GenerateCompass></GenerateCompass>
  return (
    <>
    <Canvas gl={{ antialias: true }} pixelRatio={window.devicePixelRatio}>
      <Suspense fallback={null}>
      <ambientLight intensity={0.5} />
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
      <pointLight position={[-10, -10, -10]} />
      <GenerateFloatingText></GenerateFloatingText>
      <GenerateFlyControls></GenerateFlyControls>
      <SkyBox></SkyBox>
      </Suspense>
    </Canvas>
    <GenerateCompass></GenerateCompass>
    <GenerateMenuSlider></GenerateMenuSlider>
    <GenerateInfoSlider></GenerateInfoSlider>
    <InstructionPage></InstructionPage>
    <LoaderOverlay></LoaderOverlay>
    </>
  )
}

