import React from 'react';
import styles from './MenuSlider.module.scss';
import Impressum from "./Impressum"

class MenuSlider extends React.Component {

  constructor(props){
    super(props);


    this.state={
      showMenu: false,
      showImp: false,
      submenuSelection: 0,
      filteredCurrentYear: []
    };
    this.Selection = ["ZEITREISE", "JAHRZEHNTE", "Die FHWS", "Fakultäten", "Internationales", "Gebäude", "Forschung", "Präsidenten", "Ehrungen"]
    this.currentSelection = ["ZEITREISE", "JAHRZEHNTE", "Die FHWS", "Fakultäten", "Internationales", "Gebäude", "Forschung", "Präsidenten", "Ehrungen"]
    this.SecondSelection = ["71-79", "80-89", "90-99", "00-09", "10-19", "20-21"]
    this.ThirdSelection = ["FANG", "FAS", "FAB", "FE", "FG", "FIW", "FKV", "FM", "FWI","FWIWI"]
    this.FourthSelection = ["Würzburg","Schweinfurt"]
  }

  ToggleMenu = () => {
    this.setState({showMenu: !this.state.showMenu});
  }

  ToggleImp = () => {
    this.setState({showImp: true});
  }

  CloseImp = () => {
    this.setState({showImp: false});
  }


  ToggleSubmenu = (id) => {
    //console.log(id);
    if(this.state.submenuSelection !== id) {
      this.setState({submenuSelection: id});
    }
    else {
      this.setState({submenuSelection: 0});
    } 
  }

  closeOverlay() {
    this.setState({hideoverlay: "true"});
  }

  componentDidMount() {
    this.currentSelection.length = 40;
    this.currentSelection.fill("JAHRZEHNTE", 10, 16);
    this.currentSelection.fill("Fakultäten", 20, 30);
    this.currentSelection.fill("Gebäude", 30, 32);
    //console.log(this.currentSelection);
  }

  componentDidUpdate(prevProps) {
    if (this.props.allYears !== prevProps.allYears) {
      this.setState({filteredCurrentYear: this.props.allYears[this.props.currentMenuOption]});
    }
    if (this.props.currentMenuOption !== prevProps.currentMenuOption) {
      this.setState({filteredCurrentYear: this.props.allYears[this.props.currentMenuOption]});
    }

  }

  
  render(){

    

    //Jahrzehnte
    var SecondHeader = [];
    for (let i = 0; i < this.SecondSelection.length; i++){
      SecondHeader.push(
      <div key={"Frame"+i} className={styles.SecondSelectionFrame} style={(this.props.currentMenuOption === (10+i) ? {} : {borderColor: "white"})}>
        <div key={"Header"+i} className={styles.sliderHeader} onClick={()=>this.props.changeMenuOption(10+i)}>
         {this.SecondSelection[i]}
        <div key={"Circle"+i} className={styles.hoverCircle}></div>
       </div>
      </div>
       );
    }

    //Fakultäten
    var ThirdHeader = [];
    for (let i = 0; i < this.ThirdSelection.length; i++){
      ThirdHeader.push(
      <div key={"Frame"+i} className={styles.ThirdSelectionFrame} style={(this.props.currentMenuOption === (20+i) ? {} : {borderColor: "white"})}>
        <div key={"Header"+i} className={styles.sliderHeader} onClick={()=>this.props.changeMenuOption(20+i)}>
         {this.ThirdSelection[i]}
        <div key={"Circle"+i} className={styles.hoverCircle}></div>
       </div>
      </div>
       );
    }

    //Gebäude
    var FourthHeader = [];
    for (let i = 0; i < this.FourthSelection.length; i++){
      FourthHeader.push(
      <div key={"Frame"+i} className={styles.FourthSelectionFrame} style={(this.props.currentMenuOption === (30+i) ? {} : {borderColor: "white"})}>
        <div key={"Header"+i} className={styles.sliderHeader} onClick={()=>this.props.changeMenuOption(30+i)}>
         {this.FourthSelection[i]}
        <div key={"Circle"+i} className={styles.hoverCircle}></div>
       </div>
      </div>
       );
    }

    var header = [];
    for (let i = 0; i < this.Selection.length; i++){
        //Hier werden die Submenus eingefügt
        if(i === 2) {
          header.push(
            <div key={"FrameEmpty"+i} className={(this.state.submenuSelection === 1 ? styles.selectionFrameEmptyExtend : styles.selectionFrameEmpty)}>
              <div className={(this.state.submenuSelection === 1 ? styles.SecondHeaderContainer : styles.SecondHeaderContainerHide)}>
              {SecondHeader}
              </div>
            </div>
            
          );
        }
        else if(i === 4) {
          header.push(
            <div key={"FrameEmpty"+i} className={(this.state.submenuSelection === 3 ? styles.selectionFrameEmptyExtendElse : styles.selectionFrameEmptyElse)}>
              <div className={(this.state.submenuSelection === 3 ? styles.ThirdHeaderContainer : styles.ThirdHeaderContainerHide)}>
              {ThirdHeader}
              </div>
            </div>
          );
        }

        
      
        else if(i === 6) {
          header.push(
            <div key={"FrameEmpty"+i} className={(this.state.submenuSelection === 5 ? styles.selectionFrameEmptyExtendElse : styles.selectionFrameEmptyElse)}>
              <div className={(this.state.submenuSelection === 5 ? styles.FourthHeaderContainer : styles.FourthHeaderContainerHide)}>
              {FourthHeader}
              </div>
            </div>
          );
            
        }
          
        
        //Spezielle Header mit Unterkategorien
        if (i === 1 || i === 3 || i === 5) {
          header.push(
            <div key={"Frame"+i} className={styles.selectionFrame}  style={{borderColor: "white"}}>
              <div key={"Header"+i} className={styles.sliderHeader} onClick={()=>this.ToggleSubmenu(i)}>
                {this.Selection[i]}
                <div key={"Circle"+i} className={styles.hoverCircle}></div>
                <div key={"Line"+i} className={styles.hoverLine}></div>
               
              </div>
            </div>
            );
        }

        //Alle anderen Header
        else {
          header.push(
          <div key={"Frame"+i} className={styles.selectionFrame}  style={(this.props.currentMenuOption === i ? {} : {borderColor: "white"})}>
            <div key={"Header"+i} className={styles.sliderHeader} onClick={()=>this.props.changeMenuOption(i)}>
              {this.Selection[i]}
              <div key={"Circle"+i} className={styles.hoverCircle}></div>
              <div key={"Line"+i} className={styles.hoverLine} ></div>

            </div>
          </div>
          );
        }
    }
    
    return (
      <div>
        <div className={(this.state.showMenu === true ? styles.sliderMenu : styles.sliderMenuHide)}>
          <div className={styles.headerContainer}>
            {header}
          </div>

          <div className={styles.subHead} onClick={this.ToggleImp}>IMPRESSUM</div>

          <div className={styles.sliderCloseButton} onClick={this.ToggleMenu}></div>
          <div className={styles.sliderLogo} onClick={this.ToggleMenu}>
            <div className={styles.selectionInfo}>{this.currentSelection[this.props.currentMenuOption]}</div>  
            <div className={styles.detailInfo}>
              {this.state.filteredCurrentYear[this.props.currentYear]}
              </div>  
          </div>
        </div>
        <Impressum closeImpressum={this.CloseImp} showImpressum={this.state.showImp}></Impressum>
      </div>
    )
  }
}

export default MenuSlider
